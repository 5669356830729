body::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #dbd8d6;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #7c6d6d;
  /* color of the scroll thumb */
  border-radius: 20px;
}